import Vue from "vue";
import App from "./App.vue";
import vuetify from "./config/vuetify";

import router from "./config/router.js";
import axios from './config/axios.js'

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

import Loader from "./components/loader.vue";
Vue.component("loader", Loader);


new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
