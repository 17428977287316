<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- <img v-show="false" id="institutionImage" :src="institutionData.imageUrl" height="100px" width="100px" > -->

        <v-btn class="mx-auto" color="primary" @click="generatePDF">
          Download</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  beforeMount() {
    // this.getImgData();
  },
  components: {},
  computed: {},
  data() {
    return {
      // formData: {
      //   admissionDate: "2021-12-20",
      //   applicantName: "Applicant name",
      //   birthCertificateNumber: "12541",
      //   bloodGroup: "B+",
      //   className: "FIVE",
      //   dateOfBirth: "2021-12-20",
      //   emailAddress: "demo@mail.com",
      //   fatherName: "father",
      //   fatherOccupation: "Business",
      //   gender: "MALE",
      //   groupName: "Science",
      //   height: "5.6",
      //   imageUrl:
      //     "https://dcampus.s3.ap-south-1.amazonaws.com/onlineAdmission/studentImage/dc425a91-38e4-4650-978b-df07aa6192fdfavicon.ico",
      //   isAutism: false,
      //   mobileNumber: "01000000000",
      //   motherName: "Mother",
      //   motherOccupation: "House Wife",
      //   permanentAddress: "address",
      //   presentAddress: "addres",
      //   quota: "Not applicable",
      //   religion: "ISLAM",
      //   transactionId: "15987",
      // },
      // institutionData: {
      //   instituteId: 6,
      //   instituteName: "Balarhat Adarsha School And College",
      //   instituteAddress: "Phulbari, Kurigram",
      //   onlineAdmissionFee: 200,
      //   imageUrl:
      //     "https://dcampus.s3.ap-south-1.amazonaws.com/onlineAdmission/instituteImage/9ffd39f7-c6bc-44b4-b4cb-65164df8f68aschoolInformation-6adf1fa2c94d8985f8361aaa88d64195.jpeg",
      // },
      formData: { ...this.formDataProps },
      institutionData: { ...this.institutionDataProps },
    };
  },
  methods: {
    generatePDF() {
      let data = [
        // { title: "Admission Date", value: this.formData.admissionDate },
        { title: "Application Id", value: this.formData.applicationId },
        { title: "Name", value: this.formData.applicantName },
        { title: "Gender", value: this.formData.gender },
        { title: "Birth Date", value: this.formData.dateOfBirth },
        {
          title: "Birth Certificate",
          value: this.formData.birthCertificateNumber,
        },
        { title: "Blood Group", value: this.formData.bloodGroup },
        { title: "Class", value: this.formData.className },
        { title: "Group", value: this.formData.groupName },
        { title: "Email", value: this.formData.emailAddress },
        { title: "Father's Name", value: this.formData.fatherName },
        { title: "Father's Occupati0n", value: this.formData.fatherOccupation },
        { title: "Father's NID", value: this.formData.fatherNidNumber },

        // { title: "Height", value: this.formData.height },
        // { title: "Is Autism", value: this.formData.isAutism },
        { title: "Phone", value: this.formData.mobileNumber },
        { title: "Mother's Name", value: this.formData.motherName },
        { title: "Mother's Occupation", value: this.formData.motherOccupation },
        { title: "Mother's NID", value: this.formData.motherNidNumber },
        { title: "Permanent Address", value: this.formData.permanentAddress },
        { title: "Present Address", value: this.formData.presentAddress },
        { title: "Quota", value: this.formData.quota },
        { title: "Religion", value: this.formData.religion },
        { title: "Transaction Id", value: this.formData.transactionId },
      ];
      const columns = [
        { title: "", dataKey: "title" },
        { title: "", dataKey: "value" },
      ];
      const doc = new jsPDF(("l", "mm", "a4"));
      const pages = doc.internal.getNumberOfPages();

      // let img = document.getElementById('institutionImage');
      // img.crossOrigin="anonymous"
    
      // let canvas = document.createElement("canvas");
      // canvas.width = img.width;
      // canvas.height = img.height;
      // let ctx = canvas.getContext("2d");
      // ctx.drawImage(img, 0, 0);
      // let dataURL = canvas.toDataURL("image/png");
      // console.log("Daturl===>", dataURL)

      doc.setFontSize(16).text("Admission Form", 100, 10, "center");

      doc
        .setFontSize(20)
        .text(this.institutionData.instituteName, 100, 25, "center");
      doc
        .setFontSize(16)
        .text(this.institutionData.instituteAddress, 100, 32, "center");

      doc.line(15, 35, 195, 35);
      // doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
      doc.setFontSize(14);
      doc.autoTable({
        columns,
        body: data,
        margin: { left: 15, top: 40 },
        // columnStyles:{
        //   title:{columnWidth:40},
        //   value:{columnWidth:60},
        // }
      });
      // console.log('height-->', doc.autoTable.previous.finalY);

      let currentPositionOfY = doc.autoTable.previous.finalY + 7;
      doc.line(15, currentPositionOfY, 195, currentPositionOfY);

      currentPositionOfY = currentPositionOfY + 10;
      doc.setFontSize(16).text("Notes", 15, currentPositionOfY);
      currentPositionOfY = currentPositionOfY + 10;

      let notes = [
        "School dress is mandatory",
        "Scholarships are available for poor and meritorious students",
        "Photocopy of Testimonial, Birth Certificate, NID of Parents must be provided at the time of admission",
        "Original Certificate of PSC/JSC/JDC must be provided at the time of admission",
        "Section will be decided by school authority",
        "Using cellphon during school time is a Admission revocable offense",
      ];

      for (let i = 0; i < notes.length; i++) {
        doc
          .setFontSize(10)
          .text(15, currentPositionOfY, Number(i + 1) + "." + notes[i]);
        currentPositionOfY = currentPositionOfY + 7;
      }

      currentPositionOfY = currentPositionOfY + 15;

      let text = "Teacher's Signature";
      doc
        .setLineWidth(0.5)
        .line(
          15,
          currentPositionOfY,
          Number(15 + doc.getTextWidth(text)),
          currentPositionOfY
        );
      doc.setFontSize(10).text(text, 15, currentPositionOfY + 5);

      text = "Headmaster's Signature";
      doc
        .setLineWidth(0.5)
        .line(
          155,
          currentPositionOfY,
          Number(155 + doc.getTextWidth(text)),
          currentPositionOfY
        );
      doc.setFontSize(10).text(text, 155, currentPositionOfY + 5);

      doc.save("Form.pdf");
    },

    // async getImgData(){

    //   let url = this.institutionData.imageUrl

    //   const data = await fetch(url)
    //   console.log("data====>", data);
    // }
  },
  mounted() {},
  props: {
    formDataProps: {
      type: Object,
      default: () => {},
    },
    institutionDataProps: {
      type: Object,
      default: () => {},
    },
  },
  updated() {},
  watch: {
    formDataProps() {
      this.formData = this.formDataProps;
      console.log("FormDataProps", this.formData);
    },
    institutionDataProps() {
      this.institutionData = this.institutionDataProps;
      console.log("FormDataProps", this.formData);
    },
  },
};
</script>

<style scoped>
.formdata_left {
  text-align: left;
}
</style>
