import Vue from "vue";
import VueRouter from "vue-router";

import * as slug from "./slug.js";
Vue.use(VueRouter);


import ApplicationForm from "../pages/applicationForm.vue";
import ApplicationFormPDF from "../pages/applicationFormPDF.vue";
import Bkash from "../pages/bKash.vue";
import Test from "../pages/test.vue";

const route = (path, component) => {
  return { path, component };
};
const router = new VueRouter({
  mode: "history",

  routes: [
    route("/test", Test),
    route(`${slug.APPLICATION_FORM}/:institutionId`, ApplicationForm),
    route(slug.APPLICATION_FORM_PDF, ApplicationFormPDF),
    route(slug.PAYMENT, Bkash),

    // {
    //   path: "*",
    //   redirect: "/",
    // },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      //if user click the back button, scroll back into the position where he left
      return savedPosition;
    }
    return {
      //sroll to the top
      x: 0,
      y: 0,
    };
  },
});

export default router;
