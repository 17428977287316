// const HOST = "http://localhost:5000";
// const HOST = "http://143.244.128.66:8080";
const HOST = "https://admission.dcampusbd.com";

const BASE_URL = `${HOST}/api/v1`;

//Dropdowns
export const CLASS_NAMES = `${BASE_URL}/get-all-classes-with-groups`; /*  /:institutionId */
export const GROUP_NAMES = `${BASE_URL}/dropDownList/group-names`;
export const SUBJECT_NAMES = `${BASE_URL}/get-all-subjects`; /* /:institutionId */
export const GET_ELECTIVE_SUBJECTS_BY_GROUP = `${BASE_URL}/get-all-subjects-of-group`; /* /:institutionId?groupId=10 */
export const GET_GENERAL_SUBJECT_BY_CLASS = `${BASE_URL}/get-all-general-subjects-of-class`; /* /:institutionId?classId=5 */

export const OCCUPATIONS = `${BASE_URL}/dropDownList/occupations`;
export const QUOTAS = `${BASE_URL}/dropDownList/quotas`;
export const RELIGIONS = `${BASE_URL}/dropDownList/religions`;
export const BLOOD_GROUPS = `${BASE_URL}/dropDownList/blood-groups`;
export const GENDERS = `${BASE_URL}/dropDownList/genders`;

export const APPLY = `${BASE_URL}/studentApplication/apply-online`; /*  /:institutionId will be added */

export const UPLOAD_IMG_TO_S3 = `${BASE_URL}/studentApplication/upload-student-image`;

export const GET_APPLICANT_INFORMATION = `${BASE_URL}/studentApplication/get-applicant-info`; /* /{applicantId} */
export const GET_ALL_INSTITUTES = `${HOST}/api/v1/get-all-registered-institutes?pageNumber=0&pageSize=100`;
export const GET_INSTITUTE_BY_CUSTOM_ID = `${HOST}/api/v1/institute-info-via-custom-institute-id?customInstituteId=`;
export const GET_INSTITUTE_PREV_SUBJECT_INFO_BY_CUSTOM_ID = `${HOST}/api/v1/institute-previous-subject-info?instituteId=`;

export const DOWNLOAD_FORM = `${BASE_URL}/studentApplication/get-applicant-info-pdf`;

/* Bkash  */
export const GET_WALLET_INFO = `${BASE_URL}/studentApplication/checkForWalletInformation`; /* /{applicantId}?amount={amount} */
export const CONFIRM_PAYMENT = `${BASE_URL}/studentApplication/confirm-payment`; /* /{applicantId}?paymentId={paymentId} */
