<template>
  <div class="py-4">
    <loader v-if="loadingState" />
    <div v-else>
      <v-row v-if="instituteInfo.instituteId">
        <v-col cols="12" xl="8" md="8" class="mx-auto">
          <!-- Header Information -->
          <v-row class="mb-8">
            <v-col
              cols="12"
              class="mx-auto text-center form_container px-0 pb-0"
            >
              <h1>{{ instituteInfo.instituteName }}</h1>
              <h2>{{ instituteInfo.instituteAddress }}</h2>
              <v-img
                :src="instituteInfo.imageUrl"
                height="100px"
                width="100px"
                contain
                class="mx-auto"
              ></v-img>
              <br />
              <div
                style="height: 5px; width: 100%; background-color: #a24c4c"
              ></div>
            </v-col>
          </v-row>
          <!-- Applied Student Information -->
          <v-row
            style="
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 10px;
              margin-bottom: 25px;
            "
          >
            <v-col cols="12" class="text-center">
              <h3>Already Applied? Search Information with Applicant Id</h3>
            </v-col>
            <v-col class="text-center" cols="12">
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    outlined
                    label="Insert Application Id"
                    v-model="applicationId"
                    hide-details
                    class="w-100"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    class="w-100"
                    color="primary"
                    x-large
                    @click="searchStudentInfromation"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
              <v-row
                v-if="Object.keys(applicationInformation).length"
                class="mt-8"
              >
                <v-col
                  cols="8"
                  class="mx-auto"
                  style="border: 1px solid rgba(0, 0, 0, 0.2)"
                >
                  <v-row>
                    <v-col cols="4" class="border_right">
                      <v-img
                        width="100%"
                        :src="applicationInformation.studentImage"
                      ></v-img>
                    </v-col>
                    <v-col cols="8" class="mx-auto">
                      <v-row>
                        <v-col cols="12" class="py-1 text-left border_bottom">
                          Name: {{ applicationInformation.studentName }}
                        </v-col>
                        <v-col cols="12" class="py-1 text-left border_bottom">
                          Father: {{ applicationInformation.fatherName }}
                        </v-col>
                        <v-col cols="12" class="py-1 text-left border_bottom">
                          Mother: {{ applicationInformation.motherName }}
                        </v-col>
                        <v-col cols="12" class="py-1 text-left border_bottom">
                          Payment Amount:
                          {{ applicationInformation.admissionFee }}
                        </v-col>
                        <v-col cols="12" class="py-1 text-left">
                          Payment Status:
                          {{ applicationInformation.paidStatus }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn
                    color="primary"
                    @click="downloadForm"
                    :loading="downloadFormLoader"
                    width=""
                    class="my-4 mx-2"
                  >
                    Download Form
                  </v-btn>
                  <v-btn
                    color="pink"
                    @click="makePayment"
                    style="color: white !important"
                    width=""
                    class="my-4 mx-2"
                  >
                    Make Payment
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3" class=""> </v-col>
            <v-col cols="3"> </v-col>
          </v-row>

          <!-- Application Fee Information -->
          <v-row>
            <v-col cols="12">
              <h3 class="d-flex align-items-center">
                Application Fee

                <h2
                  class="ml-4"
                  v-if="instituteInfo.feeTakingType == 'INSTITUTE_WISE'"
                >
                  {{ instituteInfo.onlineAdmissionFee }}
                </h2>
              </h3>

              <div v-if="instituteInfo.feeTakingType == 'CLASS_WISE'">
                <v-chip
                  v-for="(classData, index) in classes"
                  :key="index"
                  label
                  class="mr-4 mb-4"
                  color="primary"
                >
                  {{
                    classData.classDto.className +
                    " : " +
                    classData.classDto.classAdmissionFee
                  }}
                </v-chip>
              </div>
              <div v-if="instituteInfo.feeTakingType == 'GROUP_WISE'">
                <div
                  v-for="(classData, classIndex) in classes"
                  :key="classIndex"
                  class="my-2"
                >
                  <h4>{{ classData.classDto.className }}</h4>
                  <v-chip
                    v-for="(groupData, groupIndex) in classData.groupDtoList"
                    :key="groupIndex"
                    label
                    class="mr-4 mb-4"
                    color="primary"
                  >
                    {{
                      groupData.groupName + " : " + groupData.groupAdmissionFee
                    }}
                  </v-chip>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="mx-auto form_container px-8 py-12">
              <!-- <div class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.transactionId"
                  label="Transaction Id"
                  class="form_element mb-8"
                  hide-details
                  :rules="formRules.transactionId"
                ></v-text-field>
                <span class="text-red">*</span>
              </div> -->

              <div>
                <v-form ref="form" v-model="valid">
                  <v-row>
                    <!-- className -->
                    <v-col cols="12" class="mx-auto">
                      <v-autocomplete
                        v-model="formData.classId"
                        :items="classes"
                        item-text="classDto.className"
                        item-value="classDto.classId"
                        outlined
                        hide-details
                        dense
                        class="form_element"
                        label="Select Class"
                        :rules="formRules.className"
                      ></v-autocomplete>
                    </v-col>
                    <!-- groupName -->
                    <v-col
                      cols="12"
                      class="mx-auto"
                      v-if="groupesWithoutCommonGroup.length"
                    >
                      <v-autocomplete
                        v-model="formData.groupId"
                        :items="groupesWithoutCommonGroup"
                        item-text="groupName"
                        item-value="groupId"
                        outlined
                        hide-details
                        dense
                        class="form_element"
                        label="Select Group (Select class first to see available groups)"
                        @change="groupChangeHandler"
                      ></v-autocomplete>
                    </v-col>
                    <!-- Subjects -->
                    <v-col cols="12" class="mx-auto" v-if="subjects.length">
                      <h5 class="mt-2 mb-3">
                        These Subjects (
                        {{
                          generalSubjects.map((e) => e.subjectName).join(", ")
                        }}
                        ) are selected By default
                      </h5>
                      <v-autocomplete
                        v-model="formData.subjectIds"
                        :items="subjects"
                        item-text="subjectName"
                        item-value="subjectId"
                        outlined
                        hide-details
                        multiple
                        dense
                        class="form_element"
                        :label="`Select Subjects( Number Of Selectable is ${this.maximumNumberOfSubject} )`"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :cols="obj.colSize"
                      class="d-flex"
                      v-for="(obj, index) in formObj"
                      :key="index"
                    >
                      <!-- Text Field -->
                      <v-text-field
                        v-if="obj.type == 'textField'"
                        v-model="formData[obj.property]"
                        :label="obj.label"
                        outlined
                        :hide-details="obj.hideDetails"
                        dense
                        class="form_element"
                        :rules="formRules[obj.property]"
                      ></v-text-field>
                      <!-- Auto Complete Select -->
                      <v-autocomplete
                        v-if="obj.type == 'select'"
                        v-model="formData[obj.property]"
                        :items="obj.values"
                        outlined
                        hide-details
                        dense
                        class="form_element"
                        :label="obj.label"
                        :rules="formRules[obj.property]"
                      ></v-autocomplete>
                      <!-- Text Area -->
                      <v-textarea
                        v-if="obj.type == 'textArea'"
                        v-model="formData[obj.property]"
                        :label="obj.label"
                        outlined
                        class="form_element"
                        hide-details
                        :rules="formRules[obj.property]"
                      ></v-textarea>
                      <span v-if="obj.isRequired" class="text-red">*</span>
                    </v-col>
                    <!-- Date Picker -->
                    <v-col cols="6">
                      <date-picker
                        v-model="formData.dateOfBirth"
                        dateLabel="Date of Birth"
                      />
                    </v-col>
                    <!-- File Picker -->
                    <v-col cols="12" class="d-flex">
                      <div class="w-100">
                        <v-file-input
                          label="Student Photo"
                          append-icon="fa-upload"
                          prepend-icon=""
                          outlined
                          class="form_element w-100"
                          hide-details
                          accept="image/*"
                          show-size
                          v-model="studentImage"
                          @change="fileHandler($event)"
                        ></v-file-input>
                        <br />
                        <v-img
                          v-if="formData.imageUrl"
                          :src="formData.imageUrl"
                          width="100%"
                          contain
                          class=""
                        ></v-img>
                      </div>
                    </v-col>

                    <!-- StudentPreviousExamInfoDTO -->
                    <v-col cols="12" v-if="studentPreviousExamInfoDtos.length">
                      <h2 style="text-align: center; width: 100%">
                        Previous Exam Information
                      </h2>
                    </v-col>
                    <v-col cols="12" v-if="studentPreviousExamInfoDtos.length">
                      <v-row
                        v-for="(
                          prevExamInfo, prevExamIndex
                        ) in studentPreviousExamInfoDtos"
                        :key="prevExamIndex"
                      >
                        <h3
                          style="
                            text-align: center;
                            width: 100%;
                            padding: 20px 0;
                          "
                        >
                          <span
                            style="
                              border-bottom: 2px solid black;
                              border-top: 2px solid black;
                              padding: 2px 5px;
                            "
                          >
                            {{ prevExamInfo.previousExamName }}
                          </span>
                        </h3>
                        <v-col
                          :cols="12"
                          class="d-flex"
                          v-for="(obj, index) in prevExamObjGenerator"
                          :key="index"
                        >
                          <v-text-field
                            v-model="
                              formData.studentPreviousExamInfoDtos[
                                prevExamIndex
                              ][obj.key]
                            "
                            :label="obj.label"
                            outlined
                            :hide-details="true"
                            dense
                            class="form_element"
                            :rules="formRules.prevExamData"
                          ></v-text-field>
                          <span class="text-red">*</span>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- Button -->
                    <v-col cols="12" class="text-center">
                      <v-btn
                        @click="saveBtnHandler"
                        :disabled="!valid"
                        color="primary"
                        class="mx-2"
                      >
                        Save
                      </v-btn>
                      <v-btn @click="clear" color="error" class="mx-2"
                        >Clear</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-dialog width="50%" v-model="dialog">
              <v-sheet class="text-center pa-12" style="position: relative">
                <h2>Success</h2>
                <h3>Your Application Id is {{ applicationId }}</h3>
                <br />
                <v-btn
                  color="primary"
                  @click="downloadForm"
                  :loading="downloadFormLoader"
                >
                  Download Form
                </v-btn>

                <v-btn
                  style="position: absolute; top: 0; right: 0"
                  rounded
                  color="error"
                  width="20px"
                  @click="dialog = false"
                >
                  X
                </v-btn>
              </v-sheet>
            </v-dialog>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-card-title class="mx-auto"
          ><div class="">
            Invalid Registration Link, Institution Not Found
          </div></v-card-title
        >
      </v-row>
    </div>
  </div>
</template>

<script>
import FileDownload from "js-file-download";
import * as api from "../config/api.js";
import DatePicker from "../components/datePicker.vue";
import ApplicationPdf from "./applicationFormPDF.vue";
export default {
  beforeMount() {
    // this.getDropdownLists();
    // this.getAllInstitutes();

    this.institutionCustomId = this.$route.params.institutionId;

    this.getInstitutionInformation(this.institutionCustomId);

    if (this.$route.query.applicationId) {
      this.applicationId = this.$route.query.applicationId;
    }
  },
  components: {
    DatePicker,
    ApplicationPdf,
  },
  computed: {
    formObj() {
      return [
        // Applicant ID
        this.formObjGenerator(
          "textField",
          "applicantId",
          "Student ID",
          false,
          null,
          12
        ),
        // Applicant Name
        this.formObjGenerator(
          "textField",
          "applicantName",
          "Applicant Name",
          true,
          null,
          12
        ),
        // Fathers Name
        this.formObjGenerator(
          "textField",
          "fatherName",
          "Father Name",
          true,
          null,
          12
        ),
        // FathersOccupation
        this.formObjGenerator(
          "select",
          "fatherOccupation",
          "Father's Occupation",
          true,
          this.getFatherOccupation,
          12
        ),
        // Father Nid
        this.formObjGenerator(
          "textField",
          "fatherNidNumber",
          "Father's NID(min length 10, max length 20)",
          true,
          null,
          12
        ),
        // MothersName
        this.formObjGenerator(
          "textField",
          "motherName",
          "Mother's Name",
          true,
          null,
          12
        ),
        // MothersOccupation
        this.formObjGenerator(
          "select",
          "motherOccupation",
          "Mother's Occupation",
          true,
          this.occupations,
          12
        ),
        // Mother Nid
        this.formObjGenerator(
          "textField",
          "motherNidNumber",
          "Mother's NID(min length 10, max length 20)",
          true,
          null,
          12
        ),
        // PresentAddress
        this.formObjGenerator(
          "textArea",
          "presentAddress",
          "Present Address",
          true,
          null,
          12
        ),
        // PermanentAddress
        this.formObjGenerator(
          "textArea",
          "permanentAddress",
          "Permanent Address",
          true,
          null,
          12
        ),
        // Phone
        this.formObjGenerator(
          "textField",
          "mobileNumber",
          "Mobile Number(English)",
          true,
          null,
          6
        ),
        // Email
        this.formObjGenerator(
          "textField",
          "emailAddress",
          "Email",
          false,
          null,
          6
        ),
        // BirthCertificate
        this.formObjGenerator(
          "textField",
          "birthCertificateNumber",
          "Birth Certificate",
          true,
          null,
          6
        ),
        // Gender
        this.formObjGenerator(
          "select",
          "gender",
          "Gender",
          true,
          this.genders,
          6
        ),
        // Religion
        this.formObjGenerator(
          "select",
          "religion",
          "Religion",
          true,
          this.religions,
          6
        ),
        // Quota
        this.formObjGenerator("select", "quota", "Quota", true, this.quotas, 6),
        // BloodGroup
        this.formObjGenerator(
          "select",
          "bloodGroup",
          "Blood Group",
          true,
          this.bloodGroups,
          6
        ),
        // IsAutism
        // this.formObjGenerator(
        //   "select",
        //   "isAutism",
        //   "Is Autism",
        //   true,
        //   this.isAutisms,
        //   6
        // ),
        // Height
        // this.formObjGenerator("textField", "height", "Height", true, null, 6),
      ];
    },
    getFatherOccupation() {
      let occupations = this.occupations.filter((e) => e != "House Wife");
      console.log(occupations);
      return occupations;
    },
    prevExamObjGenerator() {
      let arr = [
        {
          label: "Previous Institute Name",
          key: "previousInstituteName",
        },
        {
          label: "Roll Number",
          key: "rollNumber",
        },
        {
          label: "Registration Number",
          key: "registrationNumber",
        },
        {
          label: "Exam Board",
          key: "examBoard",
        },
        {
          label: "Grade And Gpa",
          key: "gradeAndGpa",
        },
        {
          label: "Passing Year",
          key: "passingYear",
        },
        {
          label: "Taken Subjects",
          key: "takenSubjects",
        },
      ];
      return arr;
    },
  },
  data() {
    return {
      dialog: false,
      downloadFormLoader: false,
      formSubmitSuccess: false,
      valid: false,
      loadingState: false,
      classes: [],
      groupes: [],
      groupesWithoutCommonGroup: [],
      subjects: [],
      generalSubjects: [],
      maximumNumberOfSubject: 0,
      occupations: [],
      genders: [],
      religions: [],
      quotas: [],
      bloodGroups: [],
      isAutisms: [true, false],
      institutes: [],
      studentPreviousExamInfoDtos: [],
      selectedInstitute: null,
      institutionCustomId: null,
      instituteInfo: {
        // instituteId: 6,
        // instituteName: "Balarhat Adarsha School And College",
        // instituteAddress: "Phulbari, Kurigram",
        // onlineAdmissionFee: 200,
        // imageUrl:
        // "https://dcampus.s3.ap-south-1.amazonaws.com/onlineAdmission/instituteImage/9ffd39f7-c6bc-44b4-b4cb-65164df8f68aschoolInformation-6adf1fa2c94d8985f8361aaa88d64195.jpeg",
      },
      studentImage: null,
      applicationInformation: {},
      applicationId: null,
      formData: {
        classId: null,
        groupId: null,
        subjectIds: [],
        applicantName: null,
        fatherName: null,
        fatherOccupation: null,
        fatherNidNumber: null,
        motherName: null,
        motherOccupation: null,
        motherNidNumber: null,
        presentAddress: null,
        permanentAddress: null,
        mobileNumber: null,
        emailAddress: null,
        dateOfBirth: new Date().toISOString().substr(0, 10),
        birthCertificateNumber: null,
        gender: null,
        religion: null,
        quota: null,
        bloodGroup: null,
        isAutism: false,
        imageUrl: null,
        transactionId: null,
        studentPreviousExamInfoDtos: [],
      },
      initFormData: {
        classId: null,
        groupId: null,
        subjectIds: [],
        applicantName: null,
        fatherName: null,
        fatherOccupation: null,
        fatherNidNumber: null,
        motherName: null,
        motherOccupation: null,
        motherNidNumber: null,
        presentAddress: null,
        permanentAddress: null,
        mobileNumber: null,
        emailAddress: null,
        dateOfBirth: new Date().toISOString().substr(0, 10),
        birthCertificateNumber: null,
        gender: null,
        religion: null,
        quota: null,
        bloodGroup: null,
        isAutism: false,
        imageUrl: null,
        transactionId: null,
        studentPreviousExamInfoDtos: [],
      },
      formRules: {
        className: [(v) => !!v || "Class is required"],
        groupName: [],
        applicantName: [(v) => !!v || "Applicant Name is required"],
        fatherName: [(v) => !!v || "Father Name is required"],
        fatherOccupation: [(v) => !!v || "Occupation is required"],
        motherName: [(v) => !!v || "Mother Name is required"],
        motherOccupation: [(v) => !!v || "Occupation is required"],
        presentAddress: [(v) => !!v || "Present Address is required"],
        permanentAddress: [(v) => !!v || "Permanent Address is required"],
        mobileNumber: [
          (v) => !!v || "Phone number is required",
          (v) => (v && v.length == 11) || "Invalid Phone",
        ],
        emailAddress: [
          // (v) => /.+@.+\..+/.test(v) || "Invalid Email",
        ],
        dateOfBirth: [(v) => !!v || "Date of birth is required"],
        admissionDate: [(v) => !!v || "Mother Name is required"],
        birthCertificateNumber: [(v) => !!v || "Birth Certificate is required"],
        gender: [(v) => !!v || "Gender is required"],
        religion: [(v) => !!v || " Religion is required"],
        quota: [(v) => !!v || " quota is required"],
        bloodGroup: [(v) => !!v || " bloodGroup is required"],
        imageUrl: [(v) => !!v || " image  is required"],
        // transactionId: [(v) => !!v || " transaction id  is required"],
        fatherNidNumber: [(v) => !!v || " nid  is required"],
        motherNidNumber: [(v) => !!v || " nid  is required"],
        prevExamData: [(v) => !!v || " field  is required"],
      },
    };
  },
  methods: {
    formObjGenerator(
      type,
      property,
      label,
      isRequired,
      values,
      colSize,
      hideDetails = true
    ) {
      return {
        type,
        property,
        label,
        isRequired,
        values,
        colSize,
        hideDetails,
      };
    },
    saveBtnHandler() {
      // this.applyOnline();
      this.uploadImage();
      // console.log("Formdata===>", this.formData);
    },
    clear() {
      // this.formData = { ...this.initFormData };
      // this.$refs.form.reset();
      // this.$refs.form.resetValidation();
      window.location.reload();
    },
    fileHandler(event) {
      if (event instanceof File) {
        let reader = new FileReader();
        reader.readAsDataURL(event);
        reader.addEventListener("load", () => {
          this.formData.imageUrl = reader.result;
        });
      } else {
        this.formData.imageUrl = null;
      }
    },
    async getDropdownLists() {
      this.classes = await this.dropDownApiCall(
        api.CLASS_NAMES + "/" + this.instituteInfo.instituteId
      );
      // console.log("Classes====>", this.classes);
      this.occupations = await this.dropDownApiCall(api.OCCUPATIONS);
      this.genders = await this.dropDownApiCall(api.GENDERS);
      this.religions = await this.dropDownApiCall(api.RELIGIONS);
      this.quotas = await this.dropDownApiCall(api.QUOTAS);
      this.bloodGroups = await this.dropDownApiCall(api.BLOOD_GROUPS);
    },
    async dropDownApiCall(apiUrl) {
      let response = await this.$http.get(apiUrl);
      return response.data.payLoad;
    },
    async getInstitutionPrevSubjectInfo(institutionId) {
      let res = await this.$http.get(
        api.GET_INSTITUTE_PREV_SUBJECT_INFO_BY_CUSTOM_ID + institutionId
      );

      if (res.data?.payLoad?.length) {
        this.studentPreviousExamInfoDtos = res.data.payLoad;
        let stdData = this.studentPreviousExamInfoDtos.map((e) => ({
          previousExamId: e.previousExamId,
          previousInstituteName: "",
          rollNumber: "",
          registrationNumber: "",
          examBoard: "",
          gradeAndGpa: "",
          passingYear: "",
          takenSubjects: "",
        }));
        this.formData.studentPreviousExamInfoDtos = stdData;
      }
    },
    getInstitutionInformation(institutionId) {
      this.loadingState = true;
      this.$http
        .get(api.GET_INSTITUTE_BY_CUSTOM_ID + institutionId)
        .then(async (res) => {
          this.instituteInfo = res.data.payLoad;
          // console.log("Institution Info====>", this.instituteInfo);

          this.getDropdownLists();

          await this.getInstitutionPrevSubjectInfo(
            this.instituteInfo.instituteId
          );
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    searchStudentInfromation() {
      this.loadingState = true;
      this.$http
        .get(`${api.GET_APPLICANT_INFORMATION}/${this.applicationId}`)
        .then((res) => {
          console.log(res.data.payLoad);
          this.applicationInformation = res.data.payLoad;
        })
        .catch((error) => {
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },

    downloadForm() {
      if (this.applicationId == null) {
        alert("Application Id Missing");
      } else {
        this.downloadFormLoader = true;
        this.$http
          .get(api.DOWNLOAD_FORM + "/" + this.applicationId, {
            responseType: "blob",
          })
          .then((res) => {
            let data = res.data;
            FileDownload(data, "AdmissionForm.pdf");
          })
          .catch((error) => {
            console.log(" Error=======>", error.response.data);
            alert("error occurred");
          })
          .finally(() => {
            this.downloadFormLoader = false;
          });
      }
    },
    makePayment() {
      // console.log({
      //   applicationId: this.applicationId,
      //   amount: this.instituteInfo.onlineAdmissionFee,
      // });

      if (!this.applicationId) {
        alert("Enter Application Id");
      } else {
        window.location.href = `${process.env.BASE_URL}payment.html?applicationId=${this.applicationId}&instituteId=${this.institutionCustomId}`;
      }
    },

    applyOnline() {
      // console.log("FormData ===>", this.formData);
      this.$http
        .post(api.APPLY + "/" + this.instituteInfo.instituteId, {
          ...this.formData,
        })
        .then((res) => {
          alert("Success");
          // this.formSubmitSuccess = true;
          // this.formData["applicationId"] = res.data.payLoad.applicationId;
          this.dialog = true;
          this.applicationId = res.data.payLoad.applicationId;
          // this.formData = { ...this.initFormData };
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    uploadImage() {
      this.loadingState = true;
      let formData = new FormData();
      formData.append("studentImage", this.studentImage);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      this.$http
        .post(api.UPLOAD_IMG_TO_S3, formData, config)
        .then((res) => {
          // alert("Success");
          this.formData.imageUrl = res.data.payLoad;
          this.applyOnline();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error);
        })
        .finally(() => {
          // this.loadingState = false;
        });
    },

    formClickHandler() {
      if (this.formData.transactionId == null) {
        alert("Insert Transaction Id First");
        window.scrollTo(0, 0);
      }
    },

    getAllInstitutes() {
      this.loadingState = true;
      this.$http
        .get(api.GET_ALL_INSTITUTES)
        .then((res) => {
          // this.institutes = res.data.payLoad.content;
        })
        .catch((error) => {
          // console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    groupChangeHandler() {
      for (let i = 0; i < this.groupes.length; i++) {
        if (this.groupes[i].groupId == this.formData.groupId) {
          this.maximumNumberOfSubject =
            this.groupes[i].maxNumberOfSubjectSelectable;
          console.log(
            "maximumNumberOfSubject===>",
            this.maximumNumberOfSubject
          );
          break;
        }
      }
      this.getElectiveSubjectByGroups();
      this.getGeneralSubjectByClass();
      this.formData.subjectIds = [];
    },
    getClassGroups() {
      for (let i = 0; i < this.classes.length; i++) {
        if (this.formData.classId == this.classes[i].classDto.classId) {
          this.groupes = this.classes[i].groupDtoList;
          this.groupesWithoutCommonGroup = this.classes[i].groupDtoList.filter(
            (e) => !e.isGroupGeneral
          );
          break;
        }
      }
    },
    getElectiveSubjectByGroups() {
      this.$http
        .get(
          api.GET_ELECTIVE_SUBJECTS_BY_GROUP +
            "/" +
            this.instituteInfo.instituteId +
            "?groupId=" +
            this.formData.groupId
        )
        .then((res) => {
          this.subjects = res.data.payLoad;
          console.log("Elective Subject==>", res.data.payLoad);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },
    getGeneralSubjectByClass() {
      this.$http
        .get(
          api.GET_GENERAL_SUBJECT_BY_CLASS +
            "/" +
            this.instituteInfo.instituteId +
            "?classId=" +
            this.formData.classId
        )
        .then((res) => {
          // console.log("General Subjects==>", res.data.payLoad);
          this.generalSubjects = res.data.payLoad;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    selectedInstitute() {
      for (let i = 0; i < this.institutes.length; i++) {
        if (this.institutes[i].instituteId == this.selectedInstitute) {
          this.instituteInfo = this.institutes[i];
        }
      }
    },
    "formData.classId"() {
      this.getClassGroups();
    },
  },
};
</script>

<style scoped>
.form_container {
  background-color: #e6e6e6 !important;
}

.form_element {
  background-color: white;
}

.border_bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.border_right {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
