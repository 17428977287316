<template>
<div></div>
</template>

<script>
export default {
  beforeMount() {},
  components: {
  },
  computed: {},
  data() {
    return {
      marqueePause: false,
    };
  },
  methods: {},
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.body_container {
  border: 1px solid red;
  /* overflow-y: scroll; */
  display: flex;
  align-items: stretch;

  overflow: hidden;
  background-color: lightgoldenrodyellow;
  padding: 15px;
}
.box {
  padding: 10px 15px;
  background-color: brown;
  position: relative;

  flex: 0 0 15%;
}

.box_arrow {
  position: absolute;
  left: calc(100% - 5px);
  top: calc(50% - 5px);
  height: 10px;
  width: 10px;
  background-color: brown;
  transform: rotate(45deg);
}
.marquee_body {
  /* flex-basis: 8; */

  overflow: hidden;
  padding-left: 10px;
}
.data_container {
  border: 1px solid lightcoral;
  padding: 10px;
  display: inline-block;
  white-space: nowrap;
  background-color: lightslategrey;
  animation: animate 30s linear infinite;
}
.data_container:hover {
  animation-play-state: paused;
}
.animation_paused{
  animation-play-state: paused;

}
.marquee {
  background-color: red;
}
@keyframes animate {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.data {
  border-left: 3px solid coral;
  padding: 5px 15px;
  display: inline-block;
}
</style>
