<template>
  <div>
    payment
    <loader v-if="loadingState" />
    <v-btn id="bKash_button" color="primary">click</v-btn>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeCreate() {},
  beforeMount() {
    this.setScriptsOnHeader()
    console.log()
    
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      totalAmount: 500,
      applicantId: 6,
      paymentID: "DLNVKO01645335314454",
    };
  },
  methods: {
    initBkash() {
      console.log("clicked");
      let init = bKash.init({
        paymentMode: "checkout",
        paymentRequest: {
          amount: this.totalAmount,
          intent: "sales",
        },
        createRequest: this.createRequest(),
        // executeRequestOnAuthorization: this.executeRequestOnAuthorization(),
      });
      console.log("===>", init);
    },
    createRequest() {
      this.loadingState = true;
      this.$http
        .post(
          `${api.GET_WALLET_INFO}/${this.applicantId}?amount=${this.totalAmount}`
        )
        .then((res) => {
          console.log(res.data);
          this.paymentID = res.data.paymentID;
          bKash.create().onSuccess(res.data);
        })
        .catch((error) => {
          console.log("Error========>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    executeRequestOnAuthorization() {
      this.$http
        .post(
          `${api.CONFIRM_PAYMENT}/${this.applicantId}?paymentId=${this.paymentID}`
        )
        .then((res) => {
          alert("success");
          console.log(res.data);
        })
        .catch((error) => {
          console.log("Error========>", error.response.data);
        });
    },

    setScriptsOnHeader() {
      /* Setting Jquery Script in header */
      let jQuery = document.createElement("script");
      jQuery.setAttribute("src", "https://code.jquery.com/jquery-3.3.1.min.js");
      jQuery.setAttribute(
        "integrity",
        "sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
      );
      jQuery.setAttribute("crossorigin", "anonymous");
      document.head.appendChild(jQuery);
      /* Setting Bkash script in header */
      let bKashScript = document.createElement("script");
      bKashScript.setAttribute(
        "src",
        "https://scripts.sandbox.bka.sh/versions/1.2.0-beta/checkout/bKash-checkout-sandbox.js"
      );
      document.head.appendChild(bKashScript);

      // console.log(bKash)
    },
  },
  mounted() {
    // document.addEventListener("DOMContentLoaded", function () {
    // this.initBkash();
    // });
  },
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
#bKashFrameWrapper {
  border: 10px solid red !important;
}

.bkash_btn {
  background-color: rgba(255, 32, 207, 0.911);
  padding: 10px 13px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
}
</style>
