<template>
  <v-app class="">
  
    <div class="contents">
      <v-container>
        <router-view></router-view>
      </v-container>
    </div>
  </v-app>
</template>

<script>
export default {
  components: {},

  data: () => ({
    //
  }),
};
</script>

<style>
/* Google Font */
@import url("https://fonts.googleapis.com/css2?family=Texturina");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu");
@import url("https://fonts.googleapis.com/css2?family=Lato");
/* Basic CSS */
@import url("./css/basicBootstrap.css");
@import url("./css/globalStyles.css");
</style>

<style scoped></style>
